export const FormLabel = {
  baseStyle: {
    color: "text.primary",
    fontWeight: "normal",
    lineHeight: "27px",
    marginBottom: 1,
    _disabled: {
      opacity: 1,
    },
  },
}
